<ng-template #Button let-action="action">
  <button
    mat-raised-button
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    (click)="action.actionMethod()"
    [disabled]="!!action.disabled && !!action.disabled()"
    type="button"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #Link let-action="action">
  <a
    mat-raised-button
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [routerLink]="action.link"
    [disabled]="!!action.disabled && !!action.disabled()"
    >{{ action.actionTitle }}</a
  >
</ng-template>
<ng-template #DropdownButton let-action="action">
  <button
    mat-menu-item
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    (click)="action.actionMethod()"
    [disabled]="!!action.disabled && !!action.disabled()"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    type="button"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #DropdownLink let-action="action">
  <a
    mat-menu-item
    [ngClass]="{
      hidden: !!action.hidden && !!action.hidden(),
      disabled: !!action.disabled && !!action.disabled()
    }"
    [routerLink]="action.link"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>
<div class="card-wrapper">
  <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
    <div>
      <h2 *ngIf="cardTitle" class="card-title" (click)="onTitleClick.emit()">
        <span *ngIf="!cardTitleLink">{{ cardTitle }}</span>
        <a *ngIf="cardTitleLink" [routerLink]="cardTitleLink">{{cardTitle}}</a>
      </h2>
      <span class="subtitle" *ngIf="cardSubTitle">{{ cardSubTitle }}</span>
    </div>
    <div
      class="actions"
      *ngIf="!smallWindowSizes.includes(windowSize)"
      fxLayout="row wrap"
      fxLayoutAlign="end"
    >
      <ng-container
        *ngFor="let action of actions"
        [ngTemplateOutlet]="!!action.link ? Link : Button"
        [ngTemplateOutletContext]="{ action: action }"
      ></ng-container>
    </div>
    <div
      class="actions"
      fxLayoutGap="10px"
      *ngIf="smallWindowSizes.includes(windowSize) && visibleActionsCnt > 0"
    >
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container
          *ngFor="let action of actions"
          [ngTemplateOutlet]="!!action.link ? DropdownLink : DropdownButton"
          [ngTemplateOutletContext]="{ action: action }"
        ></ng-container>
      </mat-menu>
    </div>
  </div>
  <ng-content></ng-content>
</div>
