import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rspl-categorize-help-dialog',
  templateUrl: './discount-info-dialog.component.html',
  styleUrls: ['./discount-info-dialog.component.scss'],
})
export class DiscountInfoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DiscountInfoDialogComponent>

  ) {}
}
