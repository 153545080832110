import { autoserialize, autoserializeAs } from 'cerialize';

export class DonationDiscount implements IDonationDiscount {
  @autoserialize small: number;
  @autoserializeAs('max_small_specification') maxSmallSpecification: number;
  @autoserialize max: number;
  @autoserialize valid: boolean;

  constructor(payload: IDonationDiscount) {
    this.small = payload?.small;
    this.max = payload?.max;
    this.maxSmallSpecification = payload?.maxSmallSpecification;
    this.valid = payload?.valid;
  }
}
export interface IDonationDiscount {
  small: number; //per item discount
  maxSmallSpecification: number; //number of initial small discounted
  max: number; //max discount value
  valid: boolean; //was discount really applied
}
