import { CurrencyPipe } from '@angular/common';
import { autoserialize, autoserializeAs } from 'cerialize';
import * as moment from 'moment';
import { Address, IAddress } from './address';
import { AppType } from './app-type';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Contact, IContact } from './contact';
import { DonationDiscount, IDonationDiscount } from './donation-discount';
import {
  DonationPayment,
  DonationPaymentType,
  IDonationPayment,
} from './donation-payment';
import { Fee } from './fee';
import { FeeType } from './fee-type';
import { IPartner, Partner } from './partner';
import { IPricing, Pricing } from './pricing';
import { ISpecification, Specification } from './specification';
import { SponsorshipAlgorithm } from './sponsorship-algorithm';

export class BaseDonationLead extends BaseDTO {
  @autoserialize id?: string;
  @autoserializeAs(Contact, 'donor') donor: Contact;
  @autoserializeAs(Address, 'address') address: Address;
  @autoserializeAs(Partner, 'partner') partner?: Partner | null;
  @autoserializeAs(Fee, 'fee') fee?: Fee | null;
  @autoserializeAs(Pricing, 'pricing') pricing: Pricing;
  @autoserializeAs(Specification, 'specification') specification: Specification;
  @autoserializeAs(Specification, 'adjusted_specification')
  adjustedSpecification: Specification;
  @autoserializeAs(DonationPayment, 'payment') payment: DonationPayment;
  @autoserialize gratuity: number;
  @autoserializeAs ('application_fee_amount') applicationFeeAmount: number;
  @autoserialize date?: string;
  @autoserializeAs ('item_ids') itemIds?: string[];
  @autoserializeAs('donation_items') donationItems?: string | null;

	@autoserialize discount?: DonationDiscount | null;
	@autoserializeAs ('discounted_specification') discountedSpecification?: Specification;
	@autoserializeAs ('accepted_small_items') acceptedSmallItems?: number | null;
	@autoserializeAs ('sponsorship_algorithm') sponsorshipAlgorithm?: SponsorshipAlgorithm;

  constructor(payload: IBaseDonationLead) {
    super(payload);

    this.id = payload?.id?.toString();
    this.donor = new Contact(payload?.donor);
    this.address = new Address(payload?.address);
    this.specification = new Specification(payload?.specification);
    this.adjustedSpecification = new Specification(
      payload?.adjustedSpecification
    );
    this.pricing = new Pricing(payload?.pricing);
    this.partner = payload?.partner ? new Partner(payload?.partner) : undefined;
    this.payment = new DonationPayment(payload?.payment);
    this.gratuity = payload?.gratuity;
    this.applicationFeeAmount = payload?.applicationFeeAmount;
    this.fee = payload?.fee ? new Fee(payload?.fee) : undefined;
    this.date = payload?.date;
    this.itemIds = payload?.itemIds?.map(id => id.toString());
    this.donationItems = payload?.donationItems;

    this.discount = payload?.discount ? new DonationDiscount(payload.discount) : undefined;
    this.discountedSpecification = payload?.discountedSpecification ? new Specification(payload.discountedSpecification) : undefined;
    this.acceptedSmallItems = payload?.acceptedSmallItems;
    this.sponsorshipAlgorithm = payload?.sponsorshipAlgorithm;
  }

  public getFee(): Fee | undefined | null {
    return this.fee || this.partner?.fee;
  }

  public get zip(): string | undefined {
    return this.address?.zip;
  }

  public get state(): string | undefined {
    return this.address?.state;
  }

  public get phone(): string {
    return this.donor?.phone;
  }

  public get fullAddress(): string {
    const addr = (
      (this.address?.street ? this.address?.street + ', ' : '') +
      (this.address?.city ? this.address?.city + ', ' : '') +
      (this.address?.state ? this.address?.state + ', ' : '') +
      (this.address?.zip ? this.address?.zip : '') +
      ((this.address?.secondary?.length || 0) > 0
        ? ' #' + this.address?.secondary
        : '')
    );
    return addr.length > 0 ? addr : '/';
  }

  public get mapBoxAddress(): string {
    return this.address?.mapBoxAddress;
  }

  resupplyFee(
    appType: AppType,
    currency: CurrencyPipe,
    showType = false
  ): string {
    let resupplyFee;
    if (this.fee && this.payment.paymentType === DonationPaymentType.offline) {
      resupplyFee = this.payment.stripeApplicationFee / 100;
    } else if (resupplyFee === undefined) {
      resupplyFee = this.calculateResupplyFee(appType);
    }
    return resupplyFee
      ? currency.transform(resupplyFee) +
      (showType ? ` (${this.getFee()?.feeType || FeeType.Flat})` : '')
      : '/';
  }

  stripeFee(appType: AppType, currency: CurrencyPipe): string | null {
    let stripeFee;
    if (stripeFee === undefined) {
      stripeFee = this.calculateStripeFee(appType);
    }
    return stripeFee ? currency.transform(stripeFee) : '/';
  }

  netAmount(appType: AppType, currency: CurrencyPipe): string | null {
    let netAmount;
    if (netAmount === undefined) {
      netAmount = this.calculateNetAmount(appType);
    }
    return netAmount ? currency.transform(netAmount) : '/';
  }

  calculateResupplyFee(appType: AppType): number | undefined { //used on payments screen when listing donations
    let resupplyFee: number | undefined;
    if (
      this.payment.paymentType === DonationPaymentType.cancellation &&
      [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)
    ) {
      resupplyFee = this.pricing.cancellationFee
        ? Math.round((this.pricing.cancellationFee / 2) * 100) / 100
        : 0;
    } else if (
      this.payment.paymentType &&
      [
        DonationPaymentType.manual_payment,
        DonationPaymentType.offline,
      ].includes(this.payment.paymentType) &&
      !this.fee &&
      [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)
    ) {
      const total = this.totalWithoutTip;
      const fee = this.getFee();
      if (fee?.feeType === FeeType.BracketsV1) {
        if (total < 150) {
          resupplyFee = Number(((total * (fee.level1 || 0)) / 100).toFixed(2));
        } else if (total < 300) {
          resupplyFee = Number(((total * (fee.level2 || 0)) / 100).toFixed(2));
        } else {
          resupplyFee = Number(((total * (fee.level3 || 0)) / 100).toFixed(2));
        }
      } else if (fee?.feeType === FeeType.BracketsV2) {
        if (total < 150) {
          resupplyFee = Number(((total * (fee.level1 || 0)) / 100).toFixed(2));
        } else if (total < 500) {
          resupplyFee = Number(((total * (fee.level2 || 0)) / 100).toFixed(2));
        } else {
          resupplyFee = Number(((total * (fee.level3 || 0)) / 100).toFixed(2));
        }
      } else {
        resupplyFee = 30;
      }
    } else if (this?.hasAdjustedSpec()) {
      resupplyFee = this.payment.stripeApplicationFee / 100;
    } else {
      resupplyFee = undefined;
    }
    return resupplyFee;
  }

  calculateStripeFee(appType: AppType): number | undefined {
    let stripeFee;
    if (
      this.payment.paymentType === DonationPaymentType.cancellation &&
      [AppType.CAPTAIN, AppType.ZENDESK].includes(appType)
    ) {
      stripeFee = this.pricing.cancellationFee
        ? Math.round(((this.pricing.cancellationFee * 2.9 + 30) / 100) * 100) /
          100
        : 0;
    } else if (this?.hasAdjustedSpec()) {
      const total = Pricing.getTotalPriceUnrounded(
        this.adjustedSpecification,
        this.pricing,
        this.gratuity
      );
      stripeFee = ((Math.round(total * 100) / 100) * 2.9 + 30) / 100;
    } else {
      stripeFee = undefined;
    }
    return stripeFee ? Number(stripeFee.toFixed(2)) : undefined;
  }

  calculateNetAmount(
    appType: AppType,
    resupplyFee: number = this.calculateResupplyFee(appType) || 0,
    stripeFee: number = this.calculateStripeFee(appType) || 0
  ): number | undefined {
    let netAmount;
    if (this.payment.paymentType === DonationPaymentType.cancellation) {
      netAmount = this.pricing.cancellationFee
        ? this.pricing.cancellationFee - resupplyFee - stripeFee
        : 0;
    } else if (this?.hasAdjustedSpec()) {
      netAmount =
        this.adjustedSpecification && this.pricing
          ? Pricing.getTotalPrice(
              this.adjustedSpecification,
              this.pricing,
              this.gratuity
            ) -
            resupplyFee -
            stripeFee
          : 0;
    } else {
      netAmount = undefined;
    }
    return netAmount ? Number(netAmount?.toFixed(2)) : undefined;
  }

  get total(): number {
    return Pricing.getTotalPrice(
      this.hasAdjustedSpec() ? this.adjustedSpecification : this.specification,
      this.pricing,
      this.gratuity
    );
  }

  get totalWithoutTip(): number {
    return Pricing.getTotalPrice(
      this.hasAdjustedSpec() ? this.adjustedSpecification : this.specification,
      this.pricing
    );
  }

  get totalEstimate(): number {
    return Pricing.getTotalPrice(this.specification, this.pricing);
  }

  totalAmount(currency: CurrencyPipe): string {
    return currency.transform(this.total) || '';
  }

  hasAdjustedSpec(): boolean {
    return (
      this.adjustedSpecification.xlarge > 0 ||
      this.adjustedSpecification.large > 0 ||
      this.adjustedSpecification.medium > 0 ||
      this.adjustedSpecification.small > 0 ||
      this.adjustedSpecification.recycling > 0 ||
      this.adjustedSpecification.staircases > 0 ||
      this.adjustedSpecification.elevator > 0 ||
      this.adjustedSpecification.disassembly > 0
    );
  }

  get isPast(): boolean {
    return moment().hour(0).minute(0).second(0).millisecond(0).isAfter(moment(this.date));
  }
}

export interface IBaseDonationLead extends IBaseDTO {
  id?: string;
  donor: IContact;
  address: IAddress;
  specification: ISpecification;
  adjustedSpecification: ISpecification;
  pricing: IPricing;
  partner?: IPartner | null;
  payment?: IDonationPayment;
  gratuity: number;
  applicationFeeAmount: number;
  fee?: Fee | null;
  date?: string;
  itemIds?: string[];
  donationItems?: string | null;
	discount?: IDonationDiscount | null;
	discountedSpecification?: Specification;
	acceptedSmallItems?: number | null;
	sponsorshipAlgorithm?: SponsorshipAlgorithm;
}
