<div fxLayout="column" fxLayoutGap="0" fxFlex style="padding: 20px;">
  <div>
    <h2>Great news!</h2>
    <p>
      Your pickup qualifies for a {{data?.charityName}} sponsored rate, which helps make your
      donation even more affordable. This offer is part of our ongoing effort to
      support efficient donation pickups and provide you with the best possible
      service.
    </p>
  </div>
  <div matDialogActions fxLayout="row" fxLayoutAlign="center center">
    <button
      class="primary-btn filled-btn"
      fxFlex="100"
      (click)="reference.close()"
      cdkFocusInitial
    >
      I Understand
    </button>
  </div>
</div>
