import { InjectionToken } from '@angular/core';
import { AppType } from './app-type';
import { BuildType } from './build-type';
import { MenuItem } from './menu-item';
import { Role } from './role';

export const ENVIRONMENT = new InjectionToken<Environment>('environment');

export class Environment {
  production: boolean;
  buildType: BuildType;
  app: AppType;
  loginRedirectionRoute: string[];
  defaultRoute: string[];
  versionCheckInterval: number;
  urls: {
    captain?: string;
    donor?: string;
    baseUrl: string;
    taxReceiptUrl?: string;
    receiptUrl?: string;
    zendesk?: string;
    stripeUrl: string;
    resetPasswordUrl: string;
  };
  mapBoxAccessToken: string;
  supportNumber?: string;
  appSignal: string;
  gtmId: string;
  ga: string;
  stripeKey: string;
  special: {
    onlySmall: string[];
    smallItemPickupCharityPartners: { [key: string]: string[] };
    territoriesWithLanding: string[];
  };
  trackLocation?: boolean;
  dynamicAfterLoginRedirect?: boolean;
  optimizelyKey?: string;
  menu: MenuItem[];

  constructor(payload: IEnvironment) {
    this.production = payload?.production !== false;
    this.buildType = payload.buildType || BuildType.DEV;
    const url = this.isProductionBuild
      ? ''
      : this.isTestBuild
      ? 'testing.'
      : this.isDevBuild
      ? 'dev.'
      : this.isStagingBuild
      ? 'staging.'
      : 'demo.';
    this.app = payload?.app;

    this.loginRedirectionRoute =
      payload?.loginRedirectionRoute ??
      (this.isTax
        ? ['/', 'tax-receipt']
        : this.isDonor
        ? ['/']
        : this.isDriver
        ? ['/', 'donations', 'truck-selection']
        : ['/', 'donations']);
    this.defaultRoute =
      payload?.defaultRoute ??
      (this.isTax
        ? ['/', 'tax-receipt']
        : this.isDonor
        ? ['/']
        : ['/', 'donations']);

    this.mapBoxAccessToken =
      payload?.mapBoxAccessToken ??
      (this.buildType === BuildType.PRODUCTION
        ? 'pk.eyJ1IjoicmVzdXBwbHlqb2UiLCJhIjoiY2xiMHV1cnNoMTZ5YzNwbTl6NGtkeHZtayJ9.-LJtsSzCoCCGJyVTi0jsnw'
        : 'pk.eyJ1IjoiZ29saWphbm1pbG9zIiwiYSI6ImNsemhjdXoyaDAzbmYyanNpaTQxd3B1bjUifQ.uFxHsegld6IvKyIXUDmmbg');
    this.versionCheckInterval =
      payload?.versionCheckInterval ??
      1000 * 60 * (this.isProductionBuild ? 30 : 10);
    this.supportNumber =
      payload?.supportNumber ??
      (this.isTpl || this.isDriver
        ? '(781) 355-9453'
        : this.isDonor
        ? '(857) 342-4937'
        : undefined);
    this.urls = {
      baseUrl:
        payload?.urls?.baseUrl ?? `https://api.${url}${this.urlLastPart}`,
      taxReceiptUrl:
        payload?.urls?.taxReceiptUrl ??
        (!this.isTax
          ? this.isDonor
            ? `https://taxreceipt.${url}${this.urlLastPart}/d/`
            : `https://taxreceipt.${url}${this.urlLastPart}/c`
          : undefined),
      receiptUrl:
        payload?.urls?.receiptUrl ??
        `https://donor.${url}${this.urlLastPart}/receipt`,
      captain:
        payload?.urls?.captain ?? `https://captain.${url}${this.urlLastPart}/`,
      donor: payload?.urls?.donor ?? `https://donor.${url}${this.urlLastPart}/`,
      zendesk:
        this.isTpl || this.isDriver
          ? `http://driversupport.${this.urlLastPart}/`
          : this.isCharity
          ? `http://charitysupport.${this.urlLastPart}/`
          : undefined,
      stripeUrl:
        this.buildType === BuildType.PRODUCTION
          ? 'https://dashboard.stripe.com'
          : 'https://dashboard.stripe.com/test',
      resetPasswordUrl:
        payload?.urls?.resetPasswordUrl ??
        `https://reset.${url}${this.urlLastPart}/`,
    };
    this.gtmId =
      payload.gtmId ||
      (this.isDonor
        ? this.isProductionBuild
          ? 'GTM-WXRR4XKF'
          : 'GTM-WXRR4XKF'
        : '');
    this.ga =
      payload.ga ||
      (this.isDonor
        ? this.isProductionBuild
          ? 'G-V42TQYX2LD'
          : // : 'UA-00000000-0'
            'GTM-WXRR4XKF'
        : '');
    this.appSignal =
      payload.appSignal ||
      (this.isDonor
        ? this.isProductionBuild
          ? 'c3dfce15-c309-4488-89ce-59f84c6ffc01'
          : this.isStagingBuild
          ? '4dea2e19-9ef9-4ee5-8a96-d1ef9936d82b'
          : this.isDevBuild
          ? 'b52a49d5-5fdf-4489-a335-96a333f444b4'
          : ''
        : this.isDriver
        ? this.isProductionBuild
          ? '37b1133c-ff7f-490f-8e0b-5e2707893b7f'
          : this.isStagingBuild
          ? '9a750cd3-3c78-4d16-a56c-200c21e19583'
          : this.isDevBuild
          ? 'ef0b3a5b-6cb2-4d68-b3a0-227b7586548f'
          : ''
        : '');
    this.stripeKey =
      payload?.stripeKey ||
      (this.isDonor || this.isCaptain || this.isZendesk || this.isCharity
        ? this.isProductionBuild
          ? 'pk_live_51IbPzLION1witdQTAnASC69EjzZgS48HlVTFEleX2KSAZEbmng5Mo16WjaMk4LQ0BmXLniqxd90cDzeG5YXuL3vL00pG9KD8Hq'
          : 'pk_test_51IbPzLION1witdQTh0yDNNherDREO1jEcRYbfnIq0kUQqwLvuxcLMZqt0Yrme6HrNss8LMGM1C5sltzq0yKgFECT00tJWt123m'
        : '');
    this.special = {
      onlySmall:
        payload?.special?.onlySmall ||
        (this.isDonor
          ? this.isProductionBuild || this.isDemoBuild
            ? ['212', '343']
            : []
          : []),
      smallItemPickupCharityPartners:
        payload?.special?.smallItemPickupCharityPartners ||
        (this.isDonor
          ? this.isProductionBuild || this.isDemoBuild
            ? {
                '468': ['576', '573', '422', '583', '593'],
                '372': ['606']
              }
            : { }
          : {}),
      territoriesWithLanding: this.isDonor
        ? this.isProductionBuild || this.isDemoBuild
          ? ['3']
          : this.isDevBuild
          ? ['2']
          : ['2']
        : [],
    };
    this.trackLocation =
      payload?.trackLocation !== undefined
        ? payload.trackLocation
        : !!this.isDriver;
    this.dynamicAfterLoginRedirect =
      payload?.dynamicAfterLoginRedirect !== undefined
        ? payload.dynamicAfterLoginRedirect
        : !this.isDriver;
    this.optimizelyKey =
      payload?.optimizelyKey ||
      (this.isDevBuild
        ? 'WoDqyLGED1bPvg7Cm2q8d'
        : this.isStagingBuild
        ? '7DnWQ1aRRSghkv3hyTdw5'
        : this.isProductionBuild
        ? '8h4Zq4aWXUxs5ohSrDXg8'
        : this.isDemoBuild
        ? 'CXzdbMYRRPY2vzKKC9EWg'
        : undefined);

    this.menu = this.getMenu();
  }

  getMenu() {
    const captainMenu: MenuItem[] = [
      {
        svgIcon: 'parachute-icon',
        title: () => 'Donations',
        route: ['/', 'donations'],
      },
      {
        icon: 'map',
        title: () => 'Journeys',
        route: ['/', 'journeys'],
        visible: () => !this.isZendesk,
      },
      {
        svgIcon: 'charities-icon',
        title: () => 'Charities',
        route: ['/', 'charities'],
        visible: () => !this.isZendesk,
      },
      {
        svgIcon: 'truck-icon',
        title: () => 'Partners',
        route: ['/', 'partners'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'business',
        title: () => 'Extra Miles',
        route: ['/', 'xmiles'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'corporate_fare',
        title: () => 'Organizations',
        route: ['/', 'organizations'],
        visible: () => !this.isZendesk,
      },
      {
        svgIcon: 'users-icon',
        title: () => 'Users',
        route: ['/', 'users'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'hub',
        title: () => 'Logistics',
        route: ['/', 'logistics'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'warning',
        title: () => 'Exceptions',
        route: ['/', 'exceptions'],
        visible: () => !this.isZendesk,
      },
      {
        svgIcon: 'square-dolar',
        title: () => 'Payments',
        route: ['/', 'payments'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'hive',
        title: () => 'Markets',
        route: ['/', 'markets'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'zoom_in',
        title: () => 'TRP Map',
        route: ['/', 'trp'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'phone',
        title: () => 'Phone Leads',
        route: ['/', 'phone'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'support_agent',
        title: () => 'Phone Leads V2',
        route: ['/', 'phone-leads'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'archive',
        title: () => 'Manual Leads',
        route: ['/', 'manual-leads'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'notifications',
        title: () => 'Notifications',
        route: ['/', 'notifications'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'alarm',
        title: () => 'Reminders',
        route: ['/', 'reminders'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'phone_forwarded',
        title: () => 'Phone Configurations',
        route: ['/', 'phone-configurations'],
        visible: (data) => {
          return !this.isZendesk && data.permissions?.phoneConfigurations;
        },
      },
      {
        icon: 'route',
        title: () => 'Playback',
        route: ['/', 'playback'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'star',
        title: () => 'Public Reviews',
        route: ['/', 'public-reviews'],
        visible: (data) =>
          !this.isZendesk && data.permissions?.appConfiguration,
      },
      {
        icon: 'chair',
        title: () => 'Items',
        route: ['/', 'items'],
        visible: (data) => !this.isZendesk && data.permissions?.itemApproval,
      },
      {
        icon: 'toggle_on',
        title: () => 'Feature Flags',
        route: ['/', 'features'],
        visible: () => !this.isZendesk,
      },
      {
        icon: 'report',
        title: () => 'Concierge',
        route: ['/', 'concierge'],
        visible: () => !this.isZendesk,
      },
    ];

    const charityMenu: MenuItem[] = [
      {
        svgIcon: 'parachute-icon',
        title: () => 'Donations',
        route: ['/', 'slider'],
        visible: () => this.isDevBuild,
      },
      {
        svgIcon: 'parachute-icon',
        title: () => (this.isDevBuild ? 'Schedule' : 'Donations'),
        route: ['/', 'donations'],
      },
      {
        icon: 'table_rows',
        title: () => 'Data',
        route: ['/', 'data'],
      },
      {
        icon: 'people',
        title: () => 'Donors',
        route: ['/', 'donors'],
      },
      {
        svgIcon: 'charities-icon',
        title: () => 'Stores',
        route: ['/', 'stores'],
      },
      {
        svgIcon: 'users-icon',
        title: () => 'Users',
        route: ['/', 'users'],
        visible: (data) => !data.roles.includes(Role.STORE),
      },
      {
        icon: 'settings',
        title: () => 'Settings',
        route: ['/', 'settings'],
        visible: (data) => !data.roles.includes(Role.STORE),
      },
      {
        icon: 'settings',
        title: () => 'Settings',
        route: ['/', 'stores', 'settings'],
        visible: (data) => data.roles.includes(Role.STORE),
      },
      {
        svgIcon: 'sponsorship',
        title: () => 'Sponsorship',
        visible: (data) => data.charity?.discount,
        route: ['/'],
        expanded: ['', 'sponsorship', '', ''],
        children: [
          {
            icon: 'dashboard',
            title: () => 'Dashboard',
            route: ['/', ''],
          },
          {
            icon: 'settings',
            title: () => 'Sponsorship Settings',
            route: ['/', 'sponsorship'],
          },
          {
            icon: 'swap_horiz',
            title: () => 'Transactions',
            route: ['/', ''],
          },
          {
            icon: 'receipt_long',
            title: () => 'Receipts',
            route: ['/', ''],
          },
        ],
      },
      {
        icon: 'help_outline',
        title: () => 'Help Center',
        externalLink: this.urls.zendesk,
        visible: () => !!this.urls.zendesk,
      },
    ];

    const partnerMenu: MenuItem[] = [
      {
        svgIcon: 'parachute-icon',
        title: () => 'Donations',
        route: ['/', 'donations'],
      },
      {
        icon: 'av_timer',
        title: () => 'Pending Donations',
        route: ['/', 'slider'],
        counter: 'pendingCount',
      },
      {
        icon: 'map',
        title: () => 'Journeys',
        route: ['/', 'journeys'],
        visible: (data) => data.partner?.journey,
      },
      {
        svgIcon: 'truck-icon',
        title: () => 'Trucks',
        route: ['/', 'trucks'],
        visible: () => !this.isZendesk,
      },
      {
        svgIcon: 'users-icon',
        title: () => 'Users',
        route: ['/', 'users'],
      },
      {
        svgIcon: 'square-dolar',
        title: () => 'Payments',
        route: ['/', 'payments'],
        subtitle: {
          show: (data) =>
            data.partner && !data.partner?.payment?.setupCompleted,
          value: ' (Stripe account setup required)',
          isWarning: true,
        },
      },
      {
        icon: 'star',
        title: () => 'Reviews',
        route: ['/', 'reviews'],
      },
      {
        icon: 'route',
        title: () => 'Route Planning',
        route: ['/', 'planning'],
        visible: (data) => !data.partner?.journey,
      },
      {
        icon: 'settings',
        title: () => 'Settings',
        route: ['/', 'settings'],
      },
      {
        icon: 'help_outline',
        title: () => 'Help Center',
        externalLink: this.urls.zendesk,
        visible: () => !!this.urls.zendesk,
      },
    ];

    return this.isCaptain || this.isZendesk
      ? captainMenu
      : this.isCharity
      ? charityMenu
      : this.isTpl
      ? partnerMenu
      : [];
  }

  get urlLastPart() {
    return this.isDemoBuild ? 'resupply.cloud' : 'resupplyapp.com';
  }

  get isDevBuild(): boolean {
    return (
      this.buildType === BuildType.DEV || this.buildType === BuildType.TEST
    );
  }

  get isTestBuild(): boolean {
    return this.buildType === BuildType.TEST;
  }

  get isStagingBuild(): boolean {
    return this.buildType === BuildType.STAGING;
  }

  get isDemoBuild(): boolean {
    return this.buildType === BuildType.DEMO;
  }

  get isProductionBuild(): boolean {
    return this.buildType === BuildType.PRODUCTION;
  }

  get isCaptain(): boolean {
    return this.app === AppType.CAPTAIN;
  }

  get isCharity(): boolean {
    return this.app === AppType.CHARITY;
  }

  get isTpl(): boolean {
    return this.app === AppType.TPL;
  }

  get isDriver(): boolean {
    return this.app === AppType.DRIVER;
  }

  get isDonor(): boolean {
    return this.app === AppType.DONOR;
  }

  get isZendesk(): boolean {
    return this.app === AppType.ZENDESK;
  }

  get isTax(): boolean {
    return this.app === AppType.TAX_RECEIPT;
  }
}

export interface IEnvironment {
  production?: boolean;
  buildType?: BuildType;
  app: AppType;
  loginRedirectionRoute?: string[];
  defaultRoute?: string[];
  versionCheckInterval?: number;
  urls?: {
    captain?: string;
    donor?: string;
    baseUrl?: string;
    webFlowBaseUrl?: string;
    taxReceiptUrl?: string;
    receiptUrl?: string;
    resetPasswordUrl?: string;
  };
  mapBoxAccessToken?: string;
  supportNumber?: string;
  appSignal?: string;
  gtmId?: string;
  ga?: string;
  stripeKey?: string;
  special?: {
    onlySmall: string[];
    smallItemPickupCharityPartners?: { [key: string]: string[] };
  };
  trackLocation?: boolean;
  dynamicAfterLoginRedirect?: boolean;
  optimizelyKey?: string;
}
