import { autoserialize, autoserializeAs } from 'cerialize';
import { DonationDiscount } from './donation-discount';
import { Specification } from './specification';
import { SponsorshipAlgorithm } from './sponsorship-algorithm';

export class Pricing {
  @autoserialize small: number;
  @autoserialize medium: number;
  @autoserialize large: number;
  @autoserialize xlarge: number;
  @autoserialize staircases: number;
  @autoserialize elevator: number;
  @autoserialize disassembly: number;
  @autoserialize base: number;
  @autoserialize recycling: number;
  @autoserializeAs('fuel_fee') fuelFee: number | null;
  @autoserializeAs('cancellation_fee') cancellationFee: number | null;
  @autoserializeAs('booking_fee') bookingFee: number | null;

  constructor(payload?: IPricing) {
    this.base = payload?.base || 0;
    this.small = payload?.small || 0;
    this.medium = payload?.medium || 0;
    this.large = payload?.large || 0;
    this.xlarge = payload?.xlarge || 0;
    this.staircases = payload?.staircases || 0;
    this.elevator = payload?.elevator || null;
    this.disassembly = payload?.disassembly || 0;
    this.recycling = payload?.recycling || 0;
    this.fuelFee = payload?.fuelFee || null;
    this.cancellationFee = payload?.cancellationFee || null;
    this.bookingFee = payload?.bookingFee || null;
  }

  public static OnSerialized(instance: Pricing, json: any): void {
    Pricing.pricingDolarsToCents(json);
  }

  public static OnDeserialized(instance: Pricing, json: any): void {
    instance = Pricing.pricingCentsToDolars(instance);
  }

  public static pricingDolarsToCents(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee'].includes(x))
      .forEach((key) => {
        pricing[key] =
          pricing[key] !== null ? Math.round(pricing[key] * 100) : null;
      });
    if (pricing.fuelFee)
      pricing.fuelFee = pricing.fuelFee !== null ? pricing.fuelFee / 100 : null;
    if (pricing.fuel_fee)
      pricing.fuel_fee =
        pricing.fuel_fee !== null ? pricing.fuel_fee / 100 : null;
    return pricing;
  }

  public static pricingCentsToDolars(pricing: any): any {
    Object.keys(pricing)
      .filter((x) => !['fuelFee', 'fuel_fee'].includes(x))
      .forEach((key) => {
        pricing[key] = pricing[key] !== null ? pricing[key] / 100 : null;
      });
    pricing.fuelFee =
      pricing.fuelFee !== null ? this.formatPrice(pricing.fuelFee * 100) : null;
    return pricing;
  }

  public static getTotalPriceUnrounded(
    spec: Specification,
    pricing: Pricing,
    gratuity?: number
  ): number {
    const price = Pricing.getTotalBasePrice(spec, pricing);
    return (
      price + ((pricing.fuelFee || 0) / 100) * price + (gratuity ?? 0) / 100
    );
  }

  public static getTotalBasePrice(
    spec: Specification,
    pricing: Pricing
  ): number {
    const price =
      pricing.base +
      pricing.small * (spec.small || 0) +
      pricing.medium * (spec.medium || 0) +
      pricing.large * (spec.large || 0) +
      pricing.xlarge * (spec.xlarge || 0) +
      pricing.staircases * (spec.staircases || 0) +
      pricing.elevator * (spec.elevator || 0) +
      pricing.disassembly * (spec.disassembly || 0) +
      pricing.recycling * (spec.recycling || 0);
    return price;
  }

  public static getTotalPrice(
    spec: Specification,
    pricing: Pricing,
    gratuity?: number
  ): number {
    return (
      Math.round(Pricing.getTotalPriceUnrounded(spec, pricing) * 100) / 100 +
      (gratuity || 0) / 100
    );
  }

  public static calculateSponsoredAmount(
    specification?: Specification, 
    pricing?: Pricing, 
    discount?: DonationDiscount, 
    sponsorshipAlgorithm?: SponsorshipAlgorithm
  ): number {
    if (!specification || !pricing || !discount || !sponsorshipAlgorithm) return 0;
    const total = Pricing.getTotalBasePrice(specification, pricing) * 100;
    return Math.min(
      Math.min(
        Math.min(
          discount?.maxSmallSpecification,
          specification.small
        ) * discount?.small,
        discount?.max
      ),
      total - sponsorshipAlgorithm?.min_quote
    );
  }

  public static formatPrice(price: number): number {
    return Math.round(price * 100) / 100;
  }

  public static donorPays = (
    specification: Specification | undefined,
    pricing: Pricing,
    discount: DonationDiscount | undefined | null,
    donatableSmall: number,
    sponsorshipAlgorithm: SponsorshipAlgorithm | undefined
  ) => {
    return this.formatPrice(
      this.getTotalPriceUnrounded(specification, pricing) -
        Math.max(
          Math.min(
            (discount?.small || 0) *
              Math.min(discount?.maxSmallSpecification || 0, donatableSmall),
            discount?.max || 999999999999999,
            sponsorshipAlgorithm?.max_discount || 999999999999999
          ),
          sponsorshipAlgorithm?.min_quote || 0
        ) / 100
    );
  };

  public static charityPays = (
    discount: DonationDiscount,
    donatableSmall: number,
    sponsorshipAlgorithm: SponsorshipAlgorithm
  ) => {
    return this.formatPrice(
      Math.max(
        Math.min(
          (discount?.small || 0) *
            Math.min(discount?.maxSmallSpecification || 0, donatableSmall),
          discount?.max || 999999999999999,
          sponsorshipAlgorithm?.max_discount || 999999999999999
        ),
        sponsorshipAlgorithm?.min_quote || 0
      ) / 100
    );
  };
}

export interface IPricing {
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  staircases: number;
  elevator: number;
  disassembly: number;
  base: number;
  recycling: number;
  fuelFee: number | null;
  cancellationFee: number | null;
  bookingFee: number | null;
}
