import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BaseDonationLead,
  ButtonActivity,
  CallDetails,
  CustomActivity,
  DonationDiscount,
  ENVIRONMENT,
  Environment,
  Fee,
  InputActivity,
  Lead,
  PageActivity,
  Pricing
} from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { Observable, map, of, take } from 'rxjs';
import { BaseApiService } from '../base-api.service';
@Injectable({
  providedIn: 'root',
})
export class LeadsService extends BaseApiService<Lead> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(
      config,
      http,
      'leads',
      Lead,
      'Lead',
      ['charity', 'partner'],
      ['charity', 'partner']
    );
  }

  override deserialize(data: any): Lead {
    const callDetails = new CallDetails(
      Deserialize(data.call_details, CallDetails)
    );
    const fee = new Fee(Deserialize(data.fee, Fee));
    const discount = data?.discount ? new DonationDiscount(Deserialize(data.discount, DonationDiscount)): undefined;
    const res = new Lead({
      ...Deserialize(
        {
          ...data,
        },
        Lead
      ),
      ...Deserialize(
        {
          ...data,
        },
        BaseDonationLead
      ),
      fee,
      callDetails,
      discount,
    });
    if (res.charity?.logo && !res.charity?.logo.startsWith('http')) {
      res.charity.logo = this.config.urls.baseUrl + res.charity?.logo;
    }
    if (
      res.charity?.meta?.splitScreenConfig &&
      !res.charity?.meta.splitScreenConfig.startsWith('http')
    ) {
      res.charity.meta.splitScreenConfig =
        this.config.urls.baseUrl + res.charity.meta.splitScreenConfig;
    }
    res.setInitialValue();
    return res;
  }

  override serialize(item: Lead) {
    const res = {
      ...Serialize(item, Lead),
      ...Serialize(
        {
          ...item,
        },
        BaseDonationLead
      ),
    };
    delete res.pricing;
    delete res.payment;
    delete res.fee;
    delete res.charity_state;
    delete res.donor_state;
    delete res.partner_state;
    delete res.donation_id;
    return res;
  }

  createPaymentSetup(lead: Lead): Observable<{
    booking_fee: boolean;
    client_secret: string;
  }> {
    return this.http.post<{
      booking_fee: boolean;
      client_secret: string;
    }>(`${this.config.urls.baseUrl}/leads/${lead.id}/payment/setup`, {});
  }

  assignCharity(leadId: string): Observable<Lead> {
    return this.http.post<Lead>(
      `${this.config.urls.baseUrl}/leads/${leadId}/charity`,
      {}
    );
  }

  createLeadActivity(
    leadId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity | CustomActivity,
    value?: any,
    prefix = '',
    origin?: string
  ): Observable<any> {
    if (!leadId) {
      return of().pipe(take(1));
    }
    if (Object.values(PageActivity).includes(type as PageActivity)) {
      return this.createLeadPageActivity(
        leadId,
        type as PageActivity,
        value,
        prefix,
        origin
      ).pipe(take(1));
    } else if (Object.values(ButtonActivity).includes(type as ButtonActivity)) {
      return this.createLeadButtonActivity(
        leadId,
        type as ButtonActivity,
        value,
        prefix,
        origin
      ).pipe(take(1));
    } else if (Object.values(InputActivity).includes(type as InputActivity)) {
      return this.createLeadInputActivity(
        leadId,
        type as InputActivity,
        value,
        prefix,
        origin
      ).pipe(take(1));
    } else if (Object.values(CustomActivity).includes(type as CustomActivity)) {
      return this.createLeadCustomActivity(
        leadId,
        type as CustomActivity,
        value,
        prefix,
        origin
      ).pipe(take(1));
    }
    return of().pipe(take(1));
  }

  private createLeadPageActivity(
    leadId: string,
    page: PageActivity,
    value?: any,
    prefix?: string,
    origin?: string
  ): Observable<any> {
    return this.http.post<any>(
      this.config.urls.baseUrl + `/leads/${leadId}/activity`,
      {
        page: prefix + page,
        value,
        origin
      }
    );
  }

  private createLeadButtonActivity(
    leadId: string,
    button: ButtonActivity,
    value?: any,
    prefix?: string,
    origin?: string
  ): Observable<any> {
    return this.http.post<any>(
      this.config.urls.baseUrl + `/leads/${leadId}/activity`,
      {
        button: prefix + button,
        value,
        origin
      }
    );
  }

  private createLeadInputActivity(
    leadId: string,
    input: InputActivity,
    value?: any,
    prefix?: string,
    origin?: string
  ): Observable<any> {
    return this.http.post<any>(
      this.config.urls.baseUrl + `/leads/${leadId}/activity`,
      {
        input: prefix + input,
        value,
        origin
      }
    );
  }

  private createLeadCustomActivity(
    leadId: string,
    custom: CustomActivity,
    value?: any,
    prefix?: string,
    origin?: string
  ): Observable<any> {
    return this.http.post<any>(
      this.config.urls.baseUrl + `/leads/${leadId}/activity`,
      {
        custom: prefix + custom,
        value,
        origin
      }
    );
  }

  getPricing(zip?: string, charityId?: string): Observable<Pricing> {
    return this.http
      .get<Pricing>(this.config.urls.baseUrl + '/leads/pricing', {
        params: {
          ...(zip ? { zip } : {}),
          ...(charityId ? { charity_id: charityId } : {}),
        },
      })
      .pipe(
        map((pricing: Pricing) => {
          return new Pricing(Deserialize(pricing, Pricing));
        })
      );
  }
}
