import { Injectable } from '@angular/core';
import { Charity } from '@domains';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CharityService, ScreeningService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './charity.actions';

@Injectable()
export class CharityEffects {
  getCharities = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCharities),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getCharitiesSuccess({
              resultsCount: results.totalResults,
              results: results.results,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCharity),
      switchMap((params) =>
        this.service.find(params.id).pipe(
          map((result) => {
            return fromActions.getCharitySuccess({
              charity: result,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  createCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createCharity),
      switchMap((payload) => {
        return this.service.create(payload.charity).pipe(
          map((result) => {
            return fromActions.createCharitySuccess({
              charity: result,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  updateCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateCharity),
      switchMap((payload) =>
        this.service.update(payload.charity.id, payload.charity).pipe(
          map((result) => {
            return fromActions.updateCharitySuccess({
              charity: result,
            });
          }),
          catchError(() => [fromActions.updateCharityFail()])
        )
      )
    );
  });

  deleteCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteCharity),
      switchMap((params) =>
        this.service.delete(params.charityId).pipe(
          map((result) => {
            return fromActions.deleteCharitySuccess({
              charityId: params.charityId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  restoreCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreCharity),
      switchMap((params) =>
        this.service.restore(params.charityId).pipe(
          map((result) => {
            return fromActions.restoreCharitySuccess({
              charityId: params.charityId,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  getCharityScreening = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCharityScreening),
      switchMap((params) =>
        this.screeningService
          .filter({
            charity_id: params.charityId,
          })
          .pipe(
            map((result) => {
              return fromActions.getCharityScreeningSuccess({
                screening: result.results.length > 0 ? result.results[0] : null,
              });
            }),
            catchError(() => EMPTY)
          )
      )
    );
  });
  createCharityScreening = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createCharityScreening),
      switchMap((payload) => {
        return this.screeningService.create(payload.screening).pipe(
          map((result) => {
            return fromActions.createCharityScreeningSuccess();
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });
  updateCharityScreening = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateCharityScreening),
      switchMap((payload) =>
        this.screeningService
          .update(payload.screening.id, payload.screening)
          .pipe(
            map((result) => fromActions.updateCharityScreeningSuccess()),
            catchError(() => EMPTY)
          )
      )
    );
  });

  getCharitiesForFeatureFlags = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCharitiesForFeatureFlags),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getCharitiesForFeatureFlagsSuccess({
              resultsCount: results.totalResults,
              results: results.results.map((p) => new Charity(p)),
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  updateForFeatureFlagsCharity = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateForFeatureFlagsCharity),
      switchMap((payload) =>
        this.service.update(payload.charity.id, payload.charity).pipe(
          map((result) => {
            return fromActions.updateForFeatureFlagsCharitySuccess({
              charity: result,
            });
          }),
          catchError(() => [
            fromActions.updateForFeatureFlagsCharityFail({
              charity: payload.charity,
            }),
          ])
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: CharityService,
    private screeningService: ScreeningService
  ) {}
}
