import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Charity,
  CharityStore,
  Environment,
  ENVIRONMENT,
  Pricing,
  Screening,
  Sponsorship,
} from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { catchError, map, Observable, switchMap } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { MarketsService } from './markets.service';

@Injectable({
  providedIn: 'root',
})
export class CharityService extends BaseApiService<Charity> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
    private marketsService: MarketsService
  ) {
    super(
      config,
      http,
      'charities',
      Charity,
      'Charity',
      [],
      ['market']
    );
  }

  override deserialize(data: any): Charity {
    const res = new Charity({
      ...Deserialize(data, Charity),
      ...(data.stores
        ? {
            stores: data.stores.map(
              (s: any) => new CharityStore(Deserialize(s, CharityStore))
            ),
          }
        : {}),
      ...(data.screening
        ? {
            screening: new Screening(Deserialize(data.screening, Screening)),
          }
        : {}),
      ...(data.sponsorship
        ? {
          sponsorship: new Sponsorship(Deserialize(data.sponsorship, Sponsorship)),
          }
        : {}),
    });
    if (res.logo && !res.logo.startsWith('http')) {
      res.logo = this.config.urls.baseUrl + res.logo;
    }
    if (
      res.meta?.splitScreenConfig &&
      !res.meta.splitScreenConfig.startsWith('http')
    ) {
      res.meta.splitScreenConfig =
        this.config.urls.baseUrl + res.meta.splitScreenConfig;
    }
    res.setInitialValue();
    return res;
  }

  override serialize(item: Charity) {
    return {
      ...Serialize(
        {
          ...item,
          ...(item.market
            ? {
                market: { ...this.marketsService.serialize(item.market) },
              }
            : {}),
        },
        Charity
      ),
    };
  }

  createPaymentSetup(sponsorshipId: string): Observable<{
    client_secret: string;
  }> {
    return this.http.post<{
      client_secret: string;
    }>(
      `${this.config.urls.baseUrl}/sponsorships/${sponsorshipId}/payment/setup`,
      {
      }
    );
  }

  getSponsorship(charityId: string): Observable<Sponsorship> {
    return this.http
      .get<any[]>(
        `${this.config.urls.baseUrl}/sponsorships`, {
          params: {
            charity_id: charityId,
          },
        }
      )
      .pipe(map((res) => res.length > 0 ? Deserialize({ ...res.map(s => ({
        ...s,
        amount: s.amount !== undefined ? Pricing.formatPrice(s.amount / 100): undefined,
        max_small: s.max_small !== undefined ? Pricing.formatPrice(s.max_small / 100): undefined
      }))[0] }, Sponsorship) : null));
  }

  createSponsorship(
    charityId: string,
    sponsorship: Sponsorship
  ): Observable<Sponsorship> {
    return this.http
      .post<any>(
        `${this.config.urls.baseUrl}/sponsorships`,
        Serialize({
          ...sponsorship,
          amount: sponsorship.amount !== undefined ? sponsorship.amount * 100 : undefined,
          maxSmall: sponsorship.maxSmall !== undefined ? sponsorship.maxSmall * 100 : undefined
        }, Sponsorship)
      )
      .pipe(
        switchMap((res) => this.getSponsorship(charityId)),
        catchError(this.handleError)
      );
  }

  updateSponsorship(
    charityId: string,
    sponsorship: Sponsorship
  ): Observable<Sponsorship> {
    return this.http
      .put<any>(
        `${this.config.urls.baseUrl}/sponsorships/${sponsorship.id}`,
        Serialize({
          ...sponsorship,
          amount: sponsorship.amount !== undefined ? sponsorship.amount * 100 : undefined,
          maxSmall: sponsorship.maxSmall !== undefined ? sponsorship.maxSmall * 100 : undefined
        }, Sponsorship)
      )
      .pipe(
        switchMap((res) => this.getSponsorship(charityId)),
        catchError(this.handleError)
      );
  }

}
